import React from 'react'
// Style
import { BiGhost } from 'react-icons/bi'


export const SayHello = ({ user, setUser }) => {
  
  return (
    <div className="alt-display">
      <BiGhost className="icon-ghost"/>
      <div className="txt">
        <h2>Hi, please enter your ID</h2>
        <p>To be able to access we need your ID, enter your valid one</p>
        <input type="text" value={user} onChange={e => setUser(e.target.value)}/>
      </div>
    </div>
  )
}
