import axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
// Icons
import { BiCircle, BiCheckCircle, BiLoaderCircle } from 'react-icons/bi';

export const FormUpdating = ({
  fullPdfPack,
  setIsSubmitted,
  cityName,
  pdfIndex,
  user,
  nextPDF,
  isClear,
  dpt,
  setDpt,
  insee,
  setInsee,
  row,
  pdfPack,
  setPdfIndex,
  API_BaseURL,
}) => {
  const [num_arrete, setNum_arrete] = useState('');
  const [date_arrete, setDate_arrete] = useState('');
  const [maj_date, setMaj_date] = useState('');
  // Prescription de travaux
  // 1° List the current situation about risk
  const [pprn, setPprn] = useState(false);
  const [pprm, setPprm] = useState(false);
  const [pprt, setPprt] = useState(false);
  // 2° Define presc tvx by click
  const [tvxN, setTvxN] = useState(null);
  const [tvxM, setTvxM] = useState(null);
  const [tvxT, setTvxT] = useState(null);
  // 3° List PPRN
  const [listPprn, setListPprn] = useState([]);
  // 4° Create presc_trx obj
  const [prescTvx, setPrescTvx] = useState({});

  const clearFields = useCallback(() => {
    setNum_arrete('');
    setDate_arrete('');
    setMaj_date('');
    setInsee('');
  }, [setInsee]);

  useEffect(() => {
    isClear && clearFields();
  }, [isClear, clearFields]);

  useEffect(() => {
    if (row) {
      (row.num_arrete || row.num_arrete === '') &&
        setNum_arrete(row.num_arrete);
      (row.date_arrete || row.date_arrete === '') &&
        setDate_arrete(row.date_arrete);
      (row.maj_date || row.maj_date === '') && setMaj_date(row.maj_date);
      (row.insee || row.insee === '') && setInsee(row.insee);

      // if(typeof(JSON.parse(row.presc_tvx) === 'object') && Object.keys(JSON.parse(row.presc_tvx)).length > 0) {
      if (row.presc_tvx) {
        const obj = JSON.parse(row.presc_tvx);
        if (obj.tvxM) {
          setTvxM(true);
          setPprm(true);
        }
        if (obj.tvxT) {
          setTvxT(true);
          setPprt(true);
        }
        if (obj.tvxN && obj.tvxN.length > 0) {
          setListPprn(obj.tvxN);
          setTvxN(true);
          setPprn(true);
        }
      }
    }
  }, [row, setInsee]);

  const onSubmit = (e) => {
    e.preventDefault();
    let pdfId;
    if (pdfPack.length > 0 && pdfPack[pdfIndex]) {
      pdfId = pdfPack[pdfIndex]._id;
    }
    // Update MongoDB even if PDFs are missing
    // if(pdfPack.length === 0 && fullPdfPack.length > 0) {
    //   fullPdfPack.find(e => e.insee === insee && (pdfId = e._id))
    //   console.log(pdfId)
    // }

    const body = {
      num_arrete,
      date_arrete,
      maj_date,
      user,
      inseeCode: insee,
      pdfId,
      cityName,
      prescTvx,
    };
    // Persist thru the FullStack arr as well so the user is able to get back and see his changes
    if (row) row.presc_tvx = JSON.stringify(prescTvx);

    axios
      .post(`${API_BaseURL}/pdf-IAL`, body)
      .then(() => {
        setIsSubmitted(true);
        nextPDF();
      })
      .catch((err) => console.log(err));

    setTimeout(() => setIsSubmitted(false), 1500);
  };

  const alignByInsee = (insee) => {
    axios
      .post(`${API_BaseURL}/insee2city-name`, { insee })
      .then((res) => {
        if (res.data.data.length === 1) {
          pdfPack.find((e, i) => {
            return (
              e.pdf_url.match(new RegExp(res.data.data[0].Nom_commune, 'i')) &&
              setPdfIndex(i)
            );
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const updateByInsee = (e) => {
    const inseeValue = e.target.value;

    if (
      (inseeValue.length === 0 || !/\D/.test(inseeValue)) &&
      inseeValue.length <= 5
    ) {
      const byInsee = pdfPack.find((e) => e.insee === inseeValue);
      setInsee(inseeValue);

      if (inseeValue.length === 5) {
        !byInsee
          ? alignByInsee(inseeValue)
          : pdfPack.find((e, i) => e.insee === inseeValue && setPdfIndex(i));
      }
      // Be able to switch department by updating new insee code
      if (inseeValue.length === 2 && !(inseeValue.substr(0, 2) === dpt)) {
        setDpt(inseeValue.substr(0, 2));
      }
    }
  };

  return (
    <form>
      <input
        type="text"
        value={insee}
        onChange={updateByInsee}
        placeholder="insee code"
      />
      <input
        type="text"
        value={num_arrete}
        onChange={(e) => setNum_arrete(e.target.value)}
        name="num_arrete"
        placeholder={"Numéro d'arrêté"}
      />
      <input
        type="text"
        value={date_arrete}
        onChange={(e) => setDate_arrete(e.target.value)}
        name="date_arrete"
        placeholder="Date"
      />
      <input
        type="text"
        value={maj_date}
        onChange={(e) => setMaj_date(e.target.value)}
        name="maj_arrete"
        placeholder="Mise à jour le"
      />

      <PrescTrvx
        setPrescTvx={setPrescTvx}
        listPprn={listPprn}
        setListPprn={setListPprn}
        pprn={pprn}
        setPprn={setPprn}
        pprm={pprm}
        setPprm={setPprm}
        pprt={pprt}
        setPprt={setPprt}
        tvxN={tvxN}
        setTvxN={setTvxN}
        tvxM={tvxM}
        setTvxM={setTvxM}
        tvxT={tvxT}
        setTvxT={setTvxT}
        API_BaseURL={API_BaseURL}
        insee={insee}
      />

      <input
        type="submit"
        value="Valider"
        onClick={onSubmit}
        disabled={!num_arrete && !date_arrete && !maj_date}
      />
    </form>
  );
};

const PrescTrvx = ({
  setPrescTvx,
  listPprn,
  setListPprn,
  pprn,
  setPprn,
  pprm,
  setPprm,
  pprt,
  setPprt,
  tvxN,
  setTvxN,
  tvxM,
  setTvxM,
  tvxT,
  setTvxT,
  API_BaseURL,
  insee,
}) => {
  const [pprs, setPprs] = useState(false);
  const [isLoadingPprs, setIsLoadingPprs] = useState(false);
  const [storeRisk, setStoreRisk] = useState(
    listPprn.length === 0 ? [] : listPprn,
  );

  const toggleTvx = () => {
    setIsLoadingPprs(true);
    if (!pprs && insee) {
      axios
        .get(`${API_BaseURL}/risques-by-insee/${insee}`)
        .then((res) => {
          setPprs(true);
          const hasPprn = res.data.data.pprn.length > 0;
          const hasPprm = res.data.data.pprm;
          const hasPprt = res.data.data.pprt;
          if (hasPprn) {
            setPprn(true);
            setListPprn(res.data.data.pprn);
          }
          if (hasPprm) setPprm(true);
          if (hasPprt) setPprt(true);
          if (!hasPprn && !hasPprm && !hasPprt) setPprs(false);
          setIsLoadingPprs(false);
        })
        .catch((err) =>
          console.log(`Err toggling prescription de travaux:\n${err}`),
        );
    } else {
      if (!insee) console.log('You have to enter a valid insee code');
      setIsLoadingPprs(false);

      setPprs(false);
      setPprn(null);
      setPprm(null);
      setPprt(null);

      setStoreRisk([]);
      setTvxN(null);
    }
  };

  const checkIcon = () => {
    switch (true) {
      case isLoadingPprs: {
        return (
          <div className="loader-pprs">
            <BiLoaderCircle />
          </div>
        );
      }
      case !isLoadingPprs && pprs: {
        return <BiCheckCircle />;
      }
      case !isLoadingPprs && !pprs: {
        return <BiCircle />;
      }
      default:
        return null;
    }
  };

  const handleRisk = (risk) => {
    const isNewRisk = storeRisk.every((e) => e.num_risque !== risk.num_risque);
    const addNewRisk = [...storeRisk, risk];
    const deleteRisk = storeRisk.filter(
      (e) => e.num_risque !== risk.num_risque,
    );
    setStoreRisk(isNewRisk ? addNewRisk : deleteRisk);
  };

  // Track and update presc travaux object from MariaDB
  useEffect(() => {
    if (!(tvxN === null && tvxM === null && tvxT === null)) {
      setPrescTvx({
        tvxN: storeRisk,
        tvxM,
        tvxT,
      });
    }
  }, [tvxN, tvxM, tvxT, storeRisk, setPrescTvx]);

  // Empty field every time insee code changes
  useEffect(() => {
    setPprs(false);
    setIsLoadingPprs(false);
    setPprn(false);
    setPprm(false);
    setPprt(false);
    setTvxN(null);
    setTvxM(null);
    setTvxT(null);
    setListPprn([]);
    setStoreRisk([]);
    setPrescTvx({});
  }, [
    insee,
    setListPprn,
    setPprm,
    setPprn,
    setPprt,
    setPrescTvx,
    setTvxM,
    setTvxN,
    setTvxT,
  ]);

  return (
    <div className="presc_tvx">
      <div className="toggle" onClick={toggleTvx}>
        {checkIcon()}
        Prescription de travaux
      </div>
      <div className="pprs">
        {pprn && (
          <div
            onClick={() => setTvxN(!tvxN)}
            className={`${tvxN ? 'active' : ''}`}
          >
            <div className="title">Naturel</div>
          </div>
        )}
        {pprm && (
          <div
            onClick={() => setTvxM(!tvxM)}
            className={`${tvxM ? 'active' : ''}`}
          >
            <div className="title">Minier</div>
          </div>
        )}
        {pprt && (
          <div
            onClick={() => setTvxT(!tvxT)}
            className={`${tvxT ? 'active' : ''}`}
          >
            <div className="title">Tech</div>
          </div>
        )}
      </div>

      {tvxN && listPprn.length > 0 && (
        <div className="list_pprn">
          {listPprn.map((risk) => {
            return (
              <div
                key={risk.num_risque}
                onClick={() => handleRisk(risk)}
                // className="risk_nat"
                className={`${
                  storeRisk.every((e) => e.num_risque !== risk.num_risque)
                    ? ''
                    : 'active'
                } risk_nat`}
              >
                <p
                  // className={`${storeRisk.every(e => e.num_risque !== risk.num_risque) ? '' : 'active'} ppr_title`}
                  className="ppr_title"
                >
                  {risk.LIB_ALEA}
                </p>
                <p className="ppr_desc">{risk.lib_pprn}</p>

                <p className="ppr_desc">{risk.LIB_RISQUE}</p>
                <div className="ppr_dates">
                  <p>
                    {risk.dat_prescription
                      ? `prescrit le: ${risk.dat_prescription}`
                      : null}
                  </p>
                  <p>
                    {risk.dat_approbation &&
                      `approuvé le: ${risk.dat_approbation}`}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
