import React from 'react'
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

export const DisplayPDF = ({ pdfURL }) => {
  const corsEnabled = `https://cors-anywhere.herokuapp.com/`;
  // const corsEnabled = `https://cors.bridged.cc/`;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
      <Viewer
        fileUrl={
          process.env.NODE_ENV === "production"
            ? `${pdfURL}`
            : `${corsEnabled}${pdfURL}`
        }
      />
    </Worker>
  )
}
