import React from 'react'
// Styles
import { AiOutlineQq } from 'react-icons/ai'
import { BsChatDots } from 'react-icons/bs'

export const NotFound = () => {
  return (
    <div className="alt-display">
      <div className="txt">
        <h2>{`This department does not exist`}</h2>
        <p>Please search another department number</p>
      </div>
      <BsChatDots className="icon-chat"/>
      <AiOutlineQq className="icon-fun"/>
    </div>
  )
}
