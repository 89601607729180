import React, { useState, useEffect } from 'react';
// Components
import { FormUpdating } from './FormUpdating';
import { DptSelector } from './DptSelector';
import { WaitingForPDF } from './pdf_part/WaitingForPDF';
// Styles
import '../App.css';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { DisplayPDF } from './pdf_part/DisplayPDF';
import { NotFound } from './pdf_part/NotFound';
import { UpToDate } from './pdf_part/UpToDate';
import { MissingPDF } from './pdf_part/MissingPDF';

export const ReviewPDF = ({ user, API_BaseURL }) => {
  const PDF_BaseURL =
    process.env.NODE_ENV === 'production'
      ? '/pdfs'
      : 'http://ial.livia.io/pdfs';

  // Style
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Handle Filter Display PDF
  const [filterMode, setFilterMode] = useState(true);

  // Communication between <Dpt/> and <Form/>
  const [dpt, setDpt] = useState('');
  const [insee, setInsee] = useState('');
  const [row, setRow] = useState();
  const [pdfIndex, setPdfIndex] = useState(0);
  const [pdfPack, setPdfPack] = useState([]);
  const [fullPdfPack, setFullPdfPack] = useState([]);
  const [isClear, setIsClear] = useState(false);
  const [cityName, setCityName] = useState('');

  // PDF display block
  const [pdfURL, setPdfURL] = useState('');
  // Condition pdf section rendering
  const [hasPdfs, setHasPdfs] = useState(true);
  const [isUpToDate, setIsUpToDate] = useState(false);
  const [isFrenchDpt, setIsFrenchDpt] = useState(true);

  const prevPDF = () => setPdfIndex(pdfIndex - 1);
  const nextPDF = () => setPdfIndex(pdfIndex + 1);

  // Doesn't keep the PDF position when switching department
  useEffect(() => {
    setPdfIndex(0);
  }, [dpt]);

  function pdfBox(API_BaseURL) {
    switch (true) {
      case !isFrenchDpt:
        return <NotFound />;
      case isFrenchDpt && hasPdfs && pdfPack.length > 0 && pdfURL.length > 0:
        return <DisplayPDF pdfURL={pdfURL} />;
      case isUpToDate:
        return <UpToDate />;
      case !hasPdfs:
        return <MissingPDF />;
      default:
        return (
          <WaitingForPDF
            user={user}
            API_BaseURL={API_BaseURL}
            setDpt={setDpt}
          />
        );
    }
  }

  return (
    <>
      <div className="form">
        <IoIosArrowBack
          onClick={prevPDF}
          className={`${pdfIndex === 0 && 'arrow-disabled'} arrow left`}
        />
        <IoIosArrowForward
          onClick={nextPDF}
          className={`${
            pdfIndex + 1 === pdfPack.length && 'arrow-disabled'
          } arrow right`}
        />
        <DptSelector
          hasPdfs={hasPdfs}
          filterMode={filterMode}
          setFilterMode={setFilterMode}
          isSubmitted={isSubmitted}
          cityName={cityName}
          setCityName={setCityName}
          setIsClear={setIsClear}
          dpt={dpt}
          setDpt={setDpt}
          insee={insee}
          setInsee={setInsee}
          setRow={setRow}
          pdfPack={pdfPack}
          setPdfPack={setPdfPack}
          fullPdfPack={fullPdfPack}
          setFullPdfPack={setFullPdfPack}
          pdfIndex={pdfIndex}
          setPdfIndex={setPdfIndex}
          setPdfURL={setPdfURL}
          PDF_BaseURL={PDF_BaseURL}
          API_BaseURL={API_BaseURL}
          setHasPdfs={setHasPdfs}
          setIsUpToDate={setIsUpToDate}
          setIsFrenchDpt={setIsFrenchDpt}
        />
        <FormUpdating
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
          cityName={cityName}
          nextPDF={nextPDF}
          isClear={isClear}
          dpt={dpt}
          setDpt={setDpt}
          insee={insee}
          setInsee={setInsee}
          row={row}
          pdfPack={pdfPack}
          fullPdfPack={fullPdfPack}
          setPdfIndex={setPdfIndex}
          API_BaseURL={API_BaseURL}
          pdfIndex={pdfIndex}
          user={user}
        />
      </div>

      <div className="pdf">{pdfBox(API_BaseURL)}</div>
    </>
  );
};
