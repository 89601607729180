import React from 'react'
import { GoFileBinary } from 'react-icons/go'


export const MissingPDF = () => {
  return (
    <div className="alt-display">
      <div className="txt">
      <h2>{`Under construction...`}</h2>
        <p>This department does not have any pdfs for the moment.</p>
        <p>We are working on it..</p>
      </div>
      <GoFileBinary className="icon-fun"/>
    </div>
  )
}
