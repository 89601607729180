import React from 'react'
import { ImStarEmpty } from 'react-icons/im'

export const UpToDate = () => {
  return (
    <div className="alt-display">
      <div className="txt">
        <h2>All clear here...</h2>
        <p>This department was already updated</p>
        <p>Please select another department</p>
        <div className="line">
          <ImStarEmpty className="icon-star"/>
          <ImStarEmpty className="icon-star"/>
          <ImStarEmpty className="icon-star"/>
          <ImStarEmpty className="icon-star"/>
          <ImStarEmpty className="icon-star"/>
        </div>
      </div>
    </div>
  )
}
