import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { sanitPdfUrl, sanitPdfUrlForInsee, sanitCityName } from '../utils';

export const DptSelector = ({
  hasPdfs,
  filterMode,
  setFilterMode,
  fullPdfPack,
  setFullPdfPack,
  isSubmitted,
  cityName,
  setCityName,
  setIsClear,
  dpt,
  setDpt,
  insee,
  setInsee,
  setRow,
  pdfPack,
  setPdfPack,
  pdfIndex,
  setPdfIndex,
  setPdfURL,
  setHasPdfs,
  setIsUpToDate,
  setIsFrenchDpt,
  API_BaseURL,
  PDF_BaseURL,
}) => {
  const [currentData, setCurrentData] = useState([]);

  // Filter results to display only unhandled PDFs
  const handleFilterMode = () => {
    setFilterMode(!filterMode);
  };

  const filterByStatus = useCallback(
    (arr) => {
      if (filterMode) return arr.filter((obj) => obj.status === 0);
      return arr;
    },
    [filterMode],
  );

  useEffect(() => {
    setPdfPack(filterMode ? filterByStatus(fullPdfPack) : fullPdfPack);
  }, [filterMode, filterByStatus, setPdfPack, fullPdfPack]);

  const renderPdfNotFound = useCallback(() => {
    setHasPdfs(false);
    setIsFrenchDpt(true);
    setIsUpToDate(false);
  }, [setHasPdfs, setIsFrenchDpt, setIsUpToDate]);

  const renderIAL_PDFs = useCallback(
    (res) => {
      setHasPdfs(true);
      setIsFrenchDpt(true);
      setFullPdfPack(res.data.data);

      // if (filterByStatus(res.data.data).length === 0) {
      if (res.data.data.filter((obj) => obj.status === 0).length === 0) {
        setIsUpToDate(true);
      }
      if (res.data.data.filter((obj) => obj.status === 0).length > 0) {
        setIsUpToDate(false);
      }
      setCurrentData(res.data.currentData);
    },
    [setHasPdfs, setIsFrenchDpt, setIsUpToDate, setFullPdfPack],
  );

  const renderWrongDpt = useCallback(() => {
    setIsFrenchDpt(false);
    setHasPdfs(true);
    setIsUpToDate(false);
  }, [setIsFrenchDpt, setHasPdfs, setIsUpToDate]);

  // [ GET: PDF's url and current data ] Retrieve arrays from Mongo and Maria
  useEffect(() => {
    const getFullPack = () => {
      let count = 0;

      axios
        .get(`${API_BaseURL}/pdf-IAL/${dpt}`)
        .then((res) => {
          if (res.data.data.length === 0 && res.data.currentData.length > 0) {
            // No PDFS available on this department
            renderPdfNotFound();
            // Load Maria data
            setCurrentData(res.data.currentData);
            setFullPdfPack(res.data.data);
          } else if (
            res.data.data.length > 0 &&
            res.data.currentData.length > 0
          ) {
            // Ready to display unhandled PDFs and data
            renderIAL_PDFs(res);
          } else if (
            res.data.data.length === 0 &&
            res.data.currentData.length === 0
          ) {
            // Wrong department number
            renderWrongDpt();
          }
        })
        .catch((err) => {
          if (
            err.message === 'Request failed with status code 502' &&
            count < 10
          ) {
            count++;
            getFullPack();
            console.log('Count from catch block: ', count);
          } else {
            // setHasPdfs(false)
            console.log('Message: ', err.message);
            console.log('Name: ', err.name);
            console.log('Getting Mongo PDF and Maria data :\n', err);
          }
        });
    };

    dpt !== '97' &&
      (dpt.length === 2 || ['971', '974'].includes(dpt)) &&
      getFullPack();
  }, [
    renderWrongDpt,
    renderPdfNotFound,
    renderIAL_PDFs,
    dpt,
    setCurrentData,
    API_BaseURL,
    setFullPdfPack,
  ]);

  const isReadyToConstructURL =
    dpt !== '97' && (dpt.length === 2 || ['971', '974'].includes(dpt));
  const constructPdfUrl = useCallback(() => {
    // Wait to have a valid dpt number + a loaded pdfPack
    if (isReadyToConstructURL && pdfPack[pdfIndex])
      setPdfURL(`${PDF_BaseURL}/${dpt}/${pdfPack[pdfIndex].name}`);
  }, [dpt, PDF_BaseURL, pdfPack, pdfIndex, setPdfURL, isReadyToConstructURL]);
  // Set PDF URL
  useEffect(() => {
    isReadyToConstructURL && constructPdfUrl();
  }, [dpt, constructPdfUrl, isReadyToConstructURL]);

  // [ GET: insee ] from city name
  const cityname2insee = useCallback(
    (name) => {
      !cityName && setIsClear(true);

      cityName &&
        isReadyToConstructURL &&
        axios
          .post(`${API_BaseURL}/city-name2insee`, {
            cityName: name,
            dpt,
          })
          .then((res) => {
            // Able to set insee only if cityname is correctly sanitized ELSE empty fields
            if (res.data.data.length === 1) {
              setIsClear(false);
              setInsee(res.data.data[0].INSEE);
            } else {
              setIsClear(true);
            }
          })
          .catch((err) => console.log(err.message));
    },
    [dpt, API_BaseURL, setInsee, setIsClear, cityName, isReadyToConstructURL],
  );

  // set insee
  const guessInsee = useCallback(() => {
    if (pdfPack[pdfIndex]) {
      if (pdfPack[pdfIndex].insee) {
        setInsee(pdfPack[pdfIndex].insee);
      } else if (pdfPack[pdfIndex].commune) {
        cityname2insee(pdfPack[pdfIndex].commune);
      } else {
        // Attempt to extract insee code from pdf url
        const extractInsee = sanitPdfUrlForInsee(pdfPack[pdfIndex].pdf_url);
        if (extractInsee.substr(0, 2) === dpt) {
          axios
            .post(`${API_BaseURL}/insee2city-name`, { insee: extractInsee })
            .then((res) => {
              if (res.data.data.length === 1) {
                setInsee(res.data.data[0].INSEE);
                setCityName(res.data.data[0].Nom_commune);
              } else {
                setIsClear(true);
              }
            })
            .catch((err) => {
              console.log('❌', err.response.data.message);
              setIsClear(true);
            });
        } else {
          // Extract city name from pdf url
          const cityNameFromUrl = sanitCityName(
            sanitPdfUrl(pdfPack[pdfIndex].pdf_url),
          );
          cityname2insee(cityNameFromUrl);
        }
      }
    }
  }, [
    pdfPack,
    pdfIndex,
    cityname2insee,
    setInsee,
    API_BaseURL,
    dpt,
    setCityName,
    setIsClear,
  ]);
  // Fill form by insee
  const aligneCurrentData = useCallback(
    (insee) => {
      setRow(currentData.find((e) => e.insee === insee));
    },
    [currentData, setRow],
  );
  // set city name
  const guessCityName = useCallback(() => {
    if (pdfPack[pdfIndex]) {
      if (pdfPack[pdfIndex].commune) {
        setCityName(pdfPack[pdfIndex].commune);
      } else {
        setCityName(sanitCityName(sanitPdfUrl(pdfPack[pdfIndex].pdf_url)));
      }
    }
  }, [setCityName, pdfPack, pdfIndex]);

  // Update insee code for every change in pdf index
  useEffect(() => {
    guessInsee();
  }, [guessInsee]);
  // Align current data on current PDF
  useEffect(() => {
    aligneCurrentData(insee);
  }, [aligneCurrentData, insee]);
  // Update city name for every change in pdf index
  useEffect(() => {
    guessCityName();
  }, [guessCityName]);

  // Manually change city name -> set insee -> set index pdf from RegEx url match
  const updateByCityName = (e) => {
    const name = e.target.value;
    // set insee from city name to align current data
    cityname2insee(name);
    // align pdf index
    if (name.length >= 4)
      pdfPack.find(
        (e, i) => e.pdf_url.match(new RegExp(name, 'i')) && setPdfIndex(i),
      );

    setCityName(name);
  };
  // Watch for cityname (manual) changes -> update insee accordingly
  useEffect(() => {
    dpt.length === 2 && cityname2insee(cityName);
  }, [dpt, cityName, cityname2insee]);

  const isValidDptValue = (v) => v.length <= 2 || ['974', '971'].includes(v);

  const updateDpt = (e) => {
    const dptValue = e.target.value;
    if (dptValue.length < 2) {
      setPdfPack([]);
      setCurrentData([]);
      setCityName('');
      setPdfURL('');
      setIsClear(true);
      setIsUpToDate(false);
      // setIsFrenchDpt(false)
    }

    if (!/\D/.test(dptValue)) {
      // const isValidDptValue =
      //   dptValue.length <= 2 || ['974', '971'].includes(dptValue);
      // isValidDptValue && setDpt(dptValue);
      isValidDptValue(dptValue) && setDpt(dptValue);
    }
  };

  return (
    <div className="dpt">
      <div className={`${isSubmitted ? 'dpt-submitted ' : ''}dpt-input`}>
        <input
          type="text"
          value={dpt}
          onChange={updateDpt}
          placeholder="Dep. num"
        />
        <FilterLabel
          pdfIndex={pdfIndex}
          pdfPack={pdfPack}
          filterMode={filterMode}
          handleFilterMode={handleFilterMode}
        />
      </div>
      <div className="cityname-block">
        <h1>{cityName}</h1>

        <input
          type="text"
          value={cityName}
          onChange={updateByCityName}
          placeholder="City name"
        />
      </div>
    </div>
  );
};

const FilterLabel = ({ pdfIndex, pdfPack, filterMode, handleFilterMode }) => {
  const description = () => {
    if (pdfPack && pdfPack.length > 0 && filterMode) {
      return (
        <div className="spans">
          <span className="on">{pdfIndex + 1} : </span>
          <span>{pdfPack.length}</span>
        </div>
      );
    }

    if (pdfPack && pdfPack.length > 0 && !filterMode) {
      return (
        <div className="spans">
          <span>{pdfIndex + 1} : </span>
          <span className="off">{pdfPack.length}</span>
        </div>
      );
    }

    if (pdfPack && pdfPack.length === 0 && !filterMode) {
      return <div className="spans">All PDF mode</div>;
    }
    if (pdfPack && pdfPack.length === 0 && filterMode) {
      return <div className="spans">Filter activated</div>;
    }
  };
  return (
    <div
      className={`filter_mode ${
        filterMode ? 'filter_mode__on' : 'filter_mode__off'
      }`}
      onClick={handleFilterMode}
    >
      {description()}
    </div>
  );
};
