import axios from 'axios';
import React, { useEffect, useState } from 'react';

export const WaitingForPDF = ({ user, API_BaseURL, setDpt }) => {
  const [dptToUpdate, setDptToUpdate] = useState([]);
  const [countOutDated, setCountOutDated] = useState(0);
  const [totalOutDated, setTotalOutDated] = useState(0);

  useEffect(() => {
    axios.get(`${API_BaseURL}/get-outdated`).then((res) => {
      setDptToUpdate(res.data.data);
      setCountOutDated(res.data.distinctLen);
      setTotalOutDated(res.data.results);
    });
  }, [API_BaseURL]);

  const greetHello = () => {
    const hour = new Date().getHours();

    switch (true) {
      case hour > 6 && hour < 12:
        return 'Good morning';
      case hour > 13 && hour < 16:
        return 'Good after noon';
      case hour > 18 && hour < 21:
        return 'Good evening';
      case hour > 21 && hour < 1:
        return 'Good night';
      default:
        return 'Welcome';
    }
  };

  const pickDept = (e) => {
    // console.log(e);
    setDpt(e.dpt);
  };

  return (
    <div className="alt-display">
      <div className="txt">
        <h2>{`${greetHello()} ${user}`}</h2>
        {/* <p>Happy to see you!</p> */}
        <div className="dept_subtitle">
          <p>
            <strong>{countOutDated}</strong> dept to update
          </p>
          <p>
            <strong>{totalOutDated}</strong> total PDF to review
          </p>
        </div>
        <p>
          To start, please enter a department number in the big circle, or pick
          on from the list
        </p>
      </div>

      <div className="dept_to_update">
        <div className="dept_list">
          {dptToUpdate &&
            dptToUpdate.map((dep, index) => {
              return (
                <div
                  key={dep.dpt + dep.count}
                  className="dept_item"
                  onClick={() => pickDept(dep)}
                >
                  <p>{dep.dpt}</p>
                  <p>{dep.count}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
