// Extract city name from PDF URL
export const sanitPdfUrl = (str) =>
  str
    .split("/")
    .pop()
    .split(".pdf")[0]
    .replace(/(^|_|-)(fiche|fc)(_|-| )(commun(al|)e|ial($|-|_| ))?/gi, "")
    .replace(/(-|_| )(arreteial)(-|_| )/i, "")
    .replace(/-annexe-ap-/, "")
    .replace(/(ddt)( |-|_|)(\d)*/gi, "")
    .replace(/\d/g, "")
    // .replace(/( |-|_|^)IAL( |-|_|$)/g, "")
    .replace(/ ?_($|-)/, "")
    .replace(/(-|_| )$/, "")
    .replace(/_/g, " ")
    .replace(/( (le|la|les))$/gi, "")
    .replace(/ (d|l) /gi, " ");

// Extract insee code from PDF URL
export const sanitPdfUrlForInsee = (str) =>
  str
    .split("/")
    .pop()
    .split(".pdf")[0]
    .replace(/(\b\d{0,4}\b)|(\b\d{6,}\b)/g, "")
    .replace(/\D/g, "");

export function sanitCityName(str) {
  if (str.match(/(-| |^)saint(|e)( |-)/gi))
    str
      .replace(/(-| )sainte( |-)/gi, " ste ")
      .replace(/(-| )saint( |-)/gi, " st ");
  return (
    str
      .replace(/(^|_|-| )(fiche|fc)(_|-| )(commun(al|)e|ial($|-|_| ))?/gi, "")
      .replace(/^ficheial(-|_| )/gi, "")
      .replace(/( |^)(IAL du)( |$)/gi, "")
      .replace(/( |^)(IAL)( |$)/gi, "")
      .replace(/^(la( |-)|le( |-)|les( |-)|l'|d')|( |-)d('| )/gi, " ")
      .replace(/( |-|_)?(\d)( |-|_)?/g, "")
      // .replace(/-/g, "")
      .replace(/_/g, " ")
      .replace(/é|è|ë|ê/gi, "e")
      .replace(/à|ä|â/gi, "a")
      .replace(/ù|ü|û/gi, "u")
      .replace(/ï|î/gi, "i")
      // .replace(/ /g, "")
      .replace(/'/g, "")
      .toLowerCase()
      .trim()
  );
}
