import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReviewPDF } from './components/ReviewPDF';
import { SayHello } from './components/SayHello';
// Styles
import './App.css';

function App() {
  const [user, setUser] = useState();
  const [isPolite, setIsPolite] = useState(false);

  const API_BaseURL =
    process.env.NODE_ENV === 'production'
      ? 'http://ial.livia.io/api/review-ial'
      : 'http://127.0.0.1:2096/review-ial';

  useEffect(() => {
    axios
      .post(`${API_BaseURL}/say-hello`, { user })
      .then((res) => {
        res.data.data === 'Access Verified'
          ? setIsPolite(true)
          : setIsPolite(false);
        // Delete XHR 403 Forbidden logs from the browser console after a success authentication
        console.clear();
      })
      .catch((err) => console.log('err'));
  }, [user, API_BaseURL]);

  return (
    <div className="App">
      {!isPolite ? (
        <div className="pdf">
          <SayHello user={user} setUser={setUser} className="alt-display" />
        </div>
      ) : (
        <ReviewPDF user={user} API_BaseURL={API_BaseURL} />
      )}
      {/* <ReviewPDF user={user} API_BaseURL={API_BaseURL} /> */}
    </div>
  );
}

export default App;
